import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Col,
  Form,
  Row,
  Button,
  FormLabel,
  FormControl,
} from "react-bootstrap";

export default function SearchInputComponent(props) {
  const { setFilterText } = props;

  const [filterText, setSetText] = useState("");

  useEffect(() => {
    if (filterText === "") {
      setFilterText("");
    }
  }, [filterText, setFilterText]);

  const clearInput = useCallback(() => {
    setSetText("");
    setFilterText(filterText);
  }, [setFilterText]);

  const setSearch = useCallback(
    (e) => {
      e.preventDefault();
      setFilterText(filterText);
    },
    [filterText, setFilterText]
  );

  return (
    <Fragment>
      <Row className="row-cols-lg-auto g-0 align-items-center">
        <Col>
          <Form onSubmit={setSearch}>
            <FormControl
              onChange={(e) => setSetText(e.target.value)}
              type="text"
              className="rounded-0"
              placeholder="Enter Keyword"
              value={filterText}
            />
          </Form>
        </Col>
        <Col>
          <button
            type="button"
            className="btn btn-warning rounded-0"
            onClick={setSearch}
          >
            <i className="fa fa-search"></i>
          </button>
        </Col>
        <Col>
          <Button type="button" className="rounded-0" onClick={clearInput}>
            X
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
}
