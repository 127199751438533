import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import axiosInstance from "../../../services/AxiosInstance";
import { Button, Modal } from "react-bootstrap";
import swal from "sweetalert";
import {
  getSelectedColumn,
  setSelectColumn,
} from "../../../services/ManageCandidateService";
import { removeFvourite } from "../../../services/FavouriteService";
import SearchInputComponent from "./Common/SearchInputComponent";
import { updateCandidateJobStatus } from "../../../services/JobsService";
import { downloadCSV } from "../../../helpers/dataTableHelper";

const allClm = [
  {
    title: "ID",
    clm: "id",
    sortable: true,
    sortField: "id",
  },
  {
    title: "Out at Work",
    clm: "out_at_work",
    sortable: true,
    sortField: "out_at_work",
  },
  {
    title: "Identify As",
    clm: "identify_name",
    sortable: true,
    sortField: "identify_name",
  },
  {
    title: "Current City",
    clm: "city_name",
    sortable: true,
    sortField: "city_name",
  },
  {
    title: "About Yourself",
    clm: "about_yourself",
    sortable: true,
    sortField: "about_yourself",
  },
  {
    title: "Qualification",
    clm: "highest_education",
    sortable: true,
    sortField: "highest_education",
  },
  {
    title: "Year of Passing",
    clm: "highest_education_passing_year",
    sortable: true,
    sortField: "highest_education_passing_year",
  },
  {
    title: "College",
    clm: "institution_name",
    sortable: true,
    sortField: "institution_name",
  },
  {
    title: "Apply for internship",
    clm: "interested_in_internships",
    sortable: true,
    sortField: "interested_in_internships",
  },
  //   {
  //    title: "Current State",
  //    clm: "current_state",
  //  },
  //   {
  //     title: "Over 16 years of age",
  //     clm: "over_eighteen_years",
  //   },
  {
    title: "Internship month(s)",
    clm: "internship_available_months",
    sortable: true,
    sortField: "internship_available_months",
  },
  {
    title: "Aggregate percentage",
    clm: "aggregate_percentage",
    sortable: true,
    sortField: "aggregate_percentage",
  },

  {
    title: "Experience",
    clm: "total_experience",
    sortable: true,
    sortField: "total_experience",
  },
  {
    title: "Current Employer",
    clm: "current_previous_company",
    sortable: true,
    sortField: "current_previous_company",
  },
  {
    title: "Current Job Role",
    clm: "current_profile_role",
    sortable: true,
    sortField: "current_profile_role",
  },
  {
    title: "Skills",
    clm: "skills",
    sortable: true,
    sortField: "skills",
  },
  {
    title: "CTC",
    clm: "current_salary",
    sortable: true,
    sortField: "current_salary",
  },
];

export default function JobApplicants() {
  const { jobID } = useParams();
  const tableClm = useMemo(
    () => [
      {
        name: "View Action",
        button: "true",
        cell: (row) => (
          <>
            <Link
              target="_blank"
              to={`/${row?.id}/candidate-profile?jobID=${jobID}`}
            >
              <button className="btn btn-primary btn-sm light me-2">
                <i className="fa fa-eye"></i>
              </button>
            </Link>
            <button
              onClick={() => handleStatus(row?.id)}
              className="btn btn-sm btn-success light"
            >
              <i className="fa fa-edit"></i>
            </button>
          </>
        ),
      },
      {
        name: "Application Status",
        selector: (row) => (row.job_status ? row.job_status : "NA"),
      },
    ],
    [jobID]
  );
  const [columnModel, setColumnModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [applicant, setApplicant] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRow, setSelectedRows] = useState([]);

  const [selectedColumns, setSelectedColumn] = useState([]);
  const [columns, setColumn] = useState(tableClm);
  const [allColumns, setAllColumn] = useState(allClm);

  const [toggleCleared, setToggleCleared] = useState(false);

  const [filterText, setFilterText] = useState("");

  const [reqParams, setReqParams] = useState({
    page: currentPage,
    limit: perPage,
    sort_by: "created_at",
    sort_direction: "DESC",
    keyword: "",
    jobID: jobID,
  });

  const [fetch, setFetch] = useState(true);

  const fetchUsers = useCallback(
    (params) => {
      setToggleCleared(!toggleCleared);
      setLoading(true);

      axiosInstance
        .post("recruiter/applicant/list", params)
        .then((res) => {
          setData(res?.data?.data);
          setTotalRows(res?.data?.totalRecords);
          setLoading(false);
          setFetch(false);
        })
        .catch((err) => {
          swal("Error", err?.message, "error");
          setLoading(false);
          setFetch(false);
        });
    },
    [setFetch]
  );

  const handleFetchColumns = useCallback(() => {
    getSelectedColumn()
      .then((resp) => {
        if (
          resp.data?.data[0].search_keys !== undefined &&
          resp.data?.data[0].search_keys !== ""
        ) {
          let keys = resp.data?.data[0].search_keys;
          let keyArray = keys.split(",");
          setSelectedColumn(keyArray);

          let newTableColumn = [];
          allColumns.forEach((clm, i) => {
            if (keyArray.includes(clm?.clm)) {
              newTableColumn.push({
                name: clm?.title,
                selector: (row) => row[clm?.clm],
                sortable: clm.sortable,
                sortField: clm.sortField,
              });
            }
            return false;
          });

          const tblClm = tableClm.concat(newTableColumn);
          setColumn(tblClm);
          //fetchUsers(currentPage);
          setFetch(true);
        }
      })
      .catch((err) => console.log(err));
  }, [setFetch]);

  useEffect(() => {
    handleFetchColumns();
  }, [handleFetchColumns]);

  useEffect(() => {
    if (fetch) {
      fetchUsers(reqParams);
    }
  }, [fetch, reqParams]);

  const handleSearchText = useCallback(
    (text) => {
      setReqParams((state) => {
        return { ...state, keyword: text };
      });
      setFetch(true);
    },
    [setReqParams, setFetch]
  );

  const handleRemoveFavorite = useCallback(() => {
    const candidate = selectedRow.map((c, i) => {
      return c.id;
    });

    if (candidate?.length < 1) {
      swal(
        "Opps",
        "Please select at least 1 candidate to unfavourite.",
        "error"
      );
      return false;
    }

    removeFvourite(candidate)
      .then((resp) => {
        if (resp?.data.code === "success") {
          swal("Great Job!", resp?.data.message, "success");
          fetchUsers(currentPage);
          setToggleCleared(!toggleCleared);
          setSelectedRows([]);
        } else if (resp?.data.code === "error") {
          swal("Opps", resp?.data.message, "error");
        }
      })
      .catch((error) => {
        //console.log(error?.response?.data?.message);
        swal("Opps", error?.response?.data?.message, "error");
      });
  }, [selectedRow, currentPage]);

  const handleUnlockProfile = useCallback(() => {
    const candidate = selectedRow.map((c, i) => {
      return c.id;
    });

    if (candidate?.length < 1) {
      swal("Opps", "Please select at least 1 candidate to unlock.", "error");
      return false;
    }

    axiosInstance
      .put("/recruiter/candidates/unlock-candidates", {
        candidate_ids: candidate,
        unlockType: "A",
        jobId: jobID,
      })
      .then((resp) => {
        if (resp?.data.code === "success") {
          //fetchUsers(currentPage);
          swal("Great Job!", resp?.data.message, "success");
          setToggleCleared(!toggleCleared);
          setSelectedRows([]);
          setFetch(true);
        } else if (resp?.data.code === "error") {
          swal("Opps", resp?.data.message, "error");
        }
      })
      .catch((err) => {
        swal("Opps", err?.response?.data?.message, "error");
        //console.log(err.message);
      });
  }, [selectedRow, jobID, setFetch]);

  const handlePageChange = useCallback(
    (page) => {
      setCurrentPage(page);
      setReqParams({
        ...reqParams,
        page: page,
      });
      setFetch(true);
    },
    [setReqParams, reqParams, setFetch]
  );

  const handlePerRowsChange = useCallback(
    async (newPerPage, page) => {
      setPerPage(newPerPage);
      setReqParams({
        ...reqParams,
        limit: newPerPage,
      });
      setFetch(true);
    },
    [setReqParams, reqParams, setFetch]
  );

  const handleSelectColumn = useCallback(() => {
    const checks = document.getElementsByClassName("column-check-box");
    let selectedChecks = [];
    for (let i = 0; i < checks.length; i++) {
      if (document.getElementsByClassName("column-check-box")[i].checked) {
        selectedChecks.push(
          document.getElementsByClassName("column-check-box")[i].value
        );
      }
    }

    setSelectedColumn(selectedChecks);

    let newTableColumn = [];
    allColumns.forEach((clm, i) => {
      if (selectedChecks.includes(clm?.clm)) {
        newTableColumn.push({
          name: clm?.title,
          selector: (row) => row[clm?.clm],
          sortable: clm.sortable,
          sortField: clm.sortField,
        });
      }
    });

    const tblClm = tableClm.concat(newTableColumn);
    setColumn(tblClm);

    setSelectColumn(selectedChecks)
      .then((resp) => {
        setTimeout(() => {
          setFetch(true);
        }, 1000);
      })
      .catch((error) => swal("Opps", error.message, "error"));
    setColumnModal(false);
  }, [allColumns, setColumn, setFetch]);

  const handleAddFavorite = useCallback(() => {
    const candidate = selectedRow.map((c, i) => {
      return c.id;
    });

    if (candidate?.length < 1) {
      swal("Opps", "Please select at least 1 candidate to favorite.", "error");
      return false;
    }

    axiosInstance
      .post("/recruiter/candidates/favorite/add", {
        candidate_ids: candidate,
      })
      .then((resp) => {
        if (resp?.data.code === "success") {
          swal("Great Job!", resp?.data.message, "success");
          //fetchUsers(currentPage);
          setToggleCleared(!toggleCleared);
          setSelectedRows([]);
          setFetch(true);
        } else if (resp?.data.code === "error") {
          swal("Opps", resp?.data.message, "error");
        }
      })
      .catch((error) => {
        //console.log(error?.response?.data?.message);
        swal("Opps", error?.response?.data?.message, "error");
      });
  }, [selectedRow, setFetch]);

  const handleStatus = useCallback(
    (id) => {
      setApplicant(id);
      setStatusModal(true);
    },
    [setStatusModal, setApplicant]
  );

  const updateStatus = useCallback(
    (e) => {
      e.preventDefault();
      const formData = new FormData(e.target);
      let data = Object.fromEntries(formData);
      data["jobId"] = jobID;
      data["candidate_id"] = String(applicant);
      updateCandidateJobStatus(data)
        .then((res) => {
          swal("Nice", res.data?.message, "success");
          setStatusModal(false);
          setApplicant(0);

          //fetchUsers(currentPage);
          setToggleCleared(!toggleCleared);
          setSelectedRows([]);
          setFetch(true);
        })
        .catch((err) => {
          swal("Failed", err.response.data?.message, "error");
        });
    },
    [applicant, setApplicant, setStatusModal, setFetch, toggleCleared]
  );

  const candidateStatus = useMemo(() => {
    return [
      { value: 1, title: "CV Shortlisted" },
      { value: 2, title: "Reject" },
      { value: 3, title: "On hold" },
      { value: 4, title: "Offered" },
      { value: 5, title: "DND" },
      { value: 6, title: "Others" },
      { value: 7, title: "In process" },
      { value: 8, title: "Interview Scheduled" },
      { value: 9, title: "Viewed(Action Pending)" },
    ];
  }, []);

  const rowStyle = useMemo(() => {
    return [
      {
        when: (row) => true,
        style: (row) => {
          let bgClr = "",
            clr = "";

          if (row.alreadyUnlocked === 1) {
            bgClr = "#ffac98";
            clr = "#fff";
          }

          return {
            backgroundColor: bgClr,
            color: clr,
          };
        },
      },
    ];
  }, []);

  const handleSort = useCallback(
    (column, sortDirection) => {
      if (column?.sortField !== undefined) {
        setReqParams({
          ...reqParams,
          sort_by: column.sortField,
          sort_direction: sortDirection,
        });
        setFetch(true);
      }
    },
    [reqParams, setReqParams, setFetch]
  );

  const checkAll = useCallback((isChecked) => {
    let list = document.getElementsByClassName("tbl-columns");
    if (isChecked) {
      for (let i = 0; i < list.length; i++) {
        document.getElementsByClassName("tbl-columns")[i].checked = true;
      }
    } else {
      for (let i = 0; i < list.length; i++) {
        document.getElementsByClassName("tbl-columns")[i].checked = false;
      }
    }
  }, []);

  return (
    <>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h4 className="fs-20 font-w600  me-auto"></h4>
        <div>
          <button
            onClick={() => setColumnModal(true)}
            className="btn btn-secondary me-3 btn-sm"
          >
            Select Column
          </button>
          {/* <button to={"#"} className="btn btn-warning me-3 btn-sm">
            <i className="fas fa-search me-1"></i>Search
          </button> */}
          <button
            onClick={handleAddFavorite}
            className="btn btn-success me-3 btn-sm"
          >
            <i className="fas fa-user-check me-1"></i> Add to Favourite
          </button>
          <button
            onClick={handleUnlockProfile}
            className="btn btn-danger btn-sm me-3"
          >
            <i className="fas fa-unlock me-1"></i>Unlock Selected
          </button>

          <button
            onClick={() => downloadCSV(data, "job-applicants-")}
            className="btn btn-warning btn-sm"
          >
            <i className="fas fa-file-csv me-1"></i>Export
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="table-responsive">
            <DataTable
              className="table display dataTablesCard job-table table-responsive-xl card-table dataTable no-footer"
              columns={columns}
              data={data}
              progressPending={loading}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              conditionalRowStyles={rowStyle}
              onChangePage={handlePageChange}
              selectableRows
              persistTableHead
              subHeader
              subHeaderComponent={
                <SearchInputComponent setFilterText={handleSearchText} />
              }
              clearSelectedRows={toggleCleared}
              onSelectedRowsChange={({ selectedRows }) => {
                setSelectedRows(selectedRows);
              }}
              onSort={handleSort}
              sortServer
            />
          </div>
        </div>
      </div>
      <Modal
        className="modal fade"
        size="lg"
        show={columnModel}
        onHide={setColumnModal}
      >
        <div role="document">
          <div className="">
            <div className="modal-header">
              <h4 className="modal-title fs-20">Select columns to be shown.</h4>
              <button
                type="button"
                className="btn-close"
                onClick={() => setColumnModal(false)}
                data-dismiss="modal"
              >
                <span></span>
              </button>
            </div>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
              <div className="add-contact-box">
                <div className="add-contact-content" id="setColumn">
                  <div className="row">
                    {allColumns.map((column, i) => {
                      return (
                        <div className="col-md-4 form-group" key={i}>
                          <input
                            id={column?.clm}
                            className="form-check-input tbl-columns column-check-box"
                            type="checkbox"
                            value={column?.clm}
                            defaultChecked={selectedColumns.includes(
                              column?.clm
                            )}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={column?.clm}
                          >
                            {column.title}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                  <div className="row">
                    <div className="col-md-4 form-group">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="select-all"
                        onChange={(e) => {
                          checkAll(e.target.checked);
                        }}
                      />
                      <label className="form-check-label" htmlFor="select-all">
                        Select All
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-success"
                onClick={handleSelectColumn}
              >
                Save
              </button>
              <button
                type="button"
                onClick={() => setColumnModal(false)}
                className="btn btn-danger"
              >
                <i className="flaticon-delete-1"></i> Close
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        className="modal fade"
        size="lg"
        show={statusModal}
        onHide={setStatusModal}
        keyboard={false}
        backdrop="static"
      >
        <div role="document">
          <div className="">
            <div className="modal-header">
              <h4 className="modal-title fs-20">Update Applicant Job Status</h4>
              <button
                type="button"
                className="btn-close"
                onClick={() => setStatusModal(false)}
                data-dismiss="modal"
              >
                <span></span>
              </button>
            </div>
            <form onSubmit={updateStatus}>
              <div className="modal-body">
                <i
                  className="flaticon-cancel-12 close"
                  data-dismiss="modal"
                ></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="add-contact-box">
                      <div className="add-contact-content">
                        <div className="mb-3 form-group">
                          <label htmlFor="job_position">
                            Position<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            id="status"
                            className="form-select"
                            type="text"
                            name="status"
                            required
                          >
                            <option value="" hidden>
                              Change Candidate Status
                            </option>
                            {candidateStatus.map((status, i) => {
                              return (
                                <option value={status?.value} key={i}>
                                  {status?.title}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer d-flex justify-content-between">
                <div>
                  <button type="submit" className="btn btn-success me-2">
                    <i className="fa fa-save"></i> Save
                  </button>
                  <button
                    type="button"
                    onClick={() => setStatusModal(false)}
                    className="btn btn-danger"
                  >
                    <i className="flaticon-delete-1"></i> Close
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}
