import axios from "axios";
import { store } from "../store/store";

const axiosInstance = axios.create({
  //baseURL: `https://react-course-b798e-default-rtdb.firebaseio.com/`,
  baseURL: `${process.env.REACT_APP_API_HOST}`,
});

axiosInstance.interceptors.request.use((config) => {
  const state = store.getState();
  const token = state.auth.auth.idToken;
  config.params = config.params || {};
  //config.params["auth"] = token;
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

export default axiosInstance;
