import axios from "axios";
import { fetchUserData } from "./usrinfo";

export const sidebarAPI = async (rid) => {
  try {
    const config = await fetchUserData();
    const response = await axios.get(
      `${process.env.REACT_APP_API_HOST}recruiter/user/menus/`,
      config
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching menu items:", error);
  }
};
