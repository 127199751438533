import axios from "axios";
import swal from "sweetalert";
import Bowser from "bowser"; // A library to parse user agent string
import { publicIp } from "public-ip"; // A library to get public IP address

import { loginConfirmedAction, Logout } from "../store/actions/AuthActions";

export function signUp(email, password) {
  //axios call
  const postData = {
    email,
    password,
    returnSecureToken: true,
  };
  return axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    postData
  );
}

export async function login(email, password) {
  const browser = Bowser.getParser(window.navigator.userAgent);
  const browserInfo = browser.getResult();

  //const ipAddress = await publicIp();
  //const locationData = await axios.get(`https://ipapi.co/${ipAddress}/json/`);
  const postData = {
    email,
    password,
    login_date: new Date().toISOString(),
    logout_date: null, // will be filled during logout
    latitude: "11.111111",
    longitude: "11.11111",
    ip_address: "127.0.0.1",
    city: "Demo city",
    country: "IN",
    browser: browserInfo.browser.name,
    os: browserInfo.os.name,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    created_at: new Date().toISOString(),
    returnSecureToken: true,
  };

  return axios.post(
    // `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    `${process.env.REACT_APP_API_HOST}recruiter/user/login`,
    postData
  );
}

export function formatError(errorResponse) {
  switch (errorResponse) {
    case "EMAIL_EXISTS":
      //return 'Email already exists';
      swal("Oops", "Email already exists", "error");
      break;
    case "EMAIL_NOT_FOUND":
      //return 'Email not found';
      swal("Oops", "Email not found", "error", { button: "Try Again!" });
      break;
    case "INVALID_PASSWORD":
      //return 'Invalid Password';
      swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
      break;
    case "USER_DISABLED":
      return "User Disabled";
      break;
    default:
      return "";
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  tokenDetails.expireDate = new Date(
    new Date().getTime() + tokenDetails.expiresIn * 1000
  );
  localStorage.setItem("userDetails", JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, navigate) {
  setTimeout(() => {
    //dispatch(Logout(history));
    dispatch(Logout(navigate));
  }, timer);
}

export function checkAutoLogin(dispatch, navigate, location = "") {
  const tokenDetailsString = localStorage.getItem("userDetails");
  let tokenDetails = "";
  if (!tokenDetailsString) {
    //dispatch(Logout(navigate));
    return;
  }

  tokenDetails = JSON.parse(tokenDetailsString);
  let expireDate = new Date(tokenDetails.expireDate);
  let todaysDate = new Date();

  if (todaysDate > expireDate) {
    dispatch(Logout(navigate));
    return;
  }

  dispatch(loginConfirmedAction(tokenDetails));

  const timer = expireDate.getTime() - todaysDate.getTime();
  runLogoutTimer(dispatch, timer, navigate);

  if (location === "/login") {
    navigate("/dashboard");
  }
}
