import axios from "axios";
import { store } from "../store/store";

export function uploadFile(file) {
  const state = store.getState();
  const token = state.auth.auth.idToken;
  var formData = new FormData();
  formData.append("file", file);
  return axios.post(
    `${process.env.REACT_APP_API_HOST}recruiter/upload`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function uploadEditorFile(file) {
  const state = store.getState();
  const token = state.auth.auth.idToken;
  var formData = new FormData();
  formData.append("file", file);
  return axios.post(
    `${process.env.REACT_APP_API_HOST}editor/upload`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}
