export const NOTIFICATION_COUNT = "Notification count";

const initialState = {
  total_count: 0,
};

export default function NotificationReducer(state = initialState, actions) {
  switch (actions.type) {
    case NOTIFICATION_COUNT:
      return {
        ...state,
        total_count: actions.payload,
      };
  }
  return state;
}
