import React, { useCallback, useEffect, useState } from "react";
import { store } from "../../../store/store";
import {
  allRead,
  getNotification,
} from "../../../services/NotificationService";
import NotificationItemComponent from "./Notifications/NotificationItemComponent";
import { useDispatch } from "react-redux";
import { callSetCount } from "../../../store/actions/NotificationAction";

export default function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const state = store.getState();
  const id = state.auth.auth.rid;

  const dispatch =  useDispatch();

  const fetchNotification = useCallback(() => {
    let params = {
      user_type: "1",
      user_id: String(id),
    };

    getNotification(params)
      .then((res) => {
        setNotifications(res.data?.data?.notifications);
        if (res.data?.data?.notifications.length > 0) {
          allRead({
            user_type: "1",
            user_id: String(id),
          });
          dispatch(callSetCount(0));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [getNotification, allRead, id]);

  useEffect(() => {
    fetchNotification();
  }, []);

  return (
    <div className="card">
      {/* <div className="card-header  border-0 pb-0">
        <h4 className="card-title"></h4>
      </div> */}
      <div className="card-body">
        <div
          id="DZ_W_Todo1"
          className="widget-media dlab-scroll ps ps--active-y"
        >
          <ul className="timeline">
            {notifications.length > 0 ? (
              notifications.map((noti, i) => (
                <NotificationItemComponent item={noti} key={i} />
              ))
            ) : (
              <li>No notification found</li>
            )}
            {/* <li>
              <div className="timeline-panel">
                <div className="media-body">
                  <h5 className="mb-1">Dr sultads Send you Photo</h5>
                  <small className="d-block">29 July 2020 - 02:26 PM</small>
                </div>
              </div>
            </li>
            <li>
              <div className="timeline-panel">
                <div className="media-body">
                  <h5 className="mb-1">Report created successfully</h5>
                  <small className="d-block">29 July 2020 - 02:26 PM</small>
                </div>
              </div>
            </li>
            <li>
              <div className="timeline-panel">
                <div className="media-body">
                  <h5 className="mb-1">Reminder : Treatment Time!</h5>
                  <small className="d-block">29 July 2020 - 02:26 PM</small>
                </div>
              </div>
            </li>
            <li>
              <div className="timeline-panel">
                <div className="media-body">
                  <h5 className="mb-1">Dr sultads Send you Photo</h5>
                  <small className="d-block">29 July 2020 - 02:26 PM</small>
                </div>
              </div>
            </li>
            <li>
              <div className="timeline-panel">
                <div className="media-body">
                  <h5 className="mb-1">Resport created successfully</h5>
                  <small className="d-block">29 July 2020 - 02:26 PM</small>
                </div>
              </div>
            </li>
            <li>
              <div className="timeline-panel">
                <div className="media-body">
                  <h5 className="mb-1">Reminder : Treatment Time!</h5>
                  <small className="d-block">29 July 2020 - 02:26 PM</small>
                </div>
              </div>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
}
