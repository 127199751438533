import React, { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function CounterCard(props) {
  const { title, subtitle, iconClass, cssClass, link } = props;
  const defaultTitle = "Title";
  const defaultSubtitle = "Sub Title";
  const defaultIconClass = "fa fa-users";
  const defaultCssClass = "primary";

  const navigate = useNavigate();

  const onNavigate = useCallback(() => {
    if (link) {
      navigate(link);
    }
  }, [link]);

  return (
    <div className="col-sm-4 p-2">
      <div
        className="card mb-2"
        onClick={onNavigate}
        style={{ cursor: link ? `pointer` : "" }}
      >
        <div
          className={`card-body d-flex justify-content-between shadow rounded border border-${
            cssClass ?? defaultCssClass
          } p-3 align-items-center`}
        >
          <div>
            <h3 className="mb-0 lh-1">{title ?? defaultTitle}</h3>
            <span className="fs-14 d-block">{subtitle ?? defaultSubtitle}</span>
          </div>
          <div id="NewCustomers">
            <i
              className={`${iconClass ?? defaultIconClass} fs-3 text-${
                cssClass ?? defaultCssClass
              }`}
            ></i>
          </div>
        </div>
      </div>
    </div>
  );
}
