import axiosInstance from "../../services/AxiosInstance";
import { GET_DASHBAORD_COUNT } from "../reducers/DashboardReducer";
import swal from "sweetalert";

export function callGetDashboardCount() {
  return (dispatch) => {
    return axiosInstance
      .get("recruiter/dashboard")
      .then((response) => {
        return dispatch({
          type: GET_DASHBAORD_COUNT,
          payload: response?.data?.data,
        });
      })
      .catch((err) => {
        //console.log();
        if (err?.response?.data?.message !== undefined) {
          swal(err.message, err?.response?.data?.message, "error");
        } else {
          //console.log(err);
          swal('Error', err?.message, "error");
        }
      });
  };
}
