import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import DataTable from "react-data-table-component";
import {
  getDownlodedResume,
  getProfileView,
  getTimeLog,
  getUnlock,
} from "../../../services/SubRecruiterService";
import { useParams } from "react-router-dom";

export default function SubRecuiterReport() {
  const [unloked, setUnlocked] = useState([]);
  const [downloaded, setDownloaded] = useState([]);
  const [timelog, setTimelog] = useState([]);
  const [viewed, setViewed] = useState([]);
  const [offer, setOffers] = useState([]);
  const { id } = useParams();

  const unlockClm = useMemo(
    () => [
      {
        name: "ID",
        selector: (row) => row.id,
      },
      {
         name: "Candidate Name",
         selector: (row) => row.legal_name,
       },
       {
         name: "Candidate Email",
         selector: (row) => row.email,
       },
      {
        name: "Unlock Time",
        selector: (row) => row.created_at,
      },
    ],
    []
  );

  const downloadClm = useMemo(
    () => [
      {
        name: "ID",
        selector: (row) => row.id,
      },
      {
        name: "Candidate Name",
        selector: (row) => row.legal_name,
      },
      {
        name: "Candidate Email",
        selector: (row) => row.email,
      },
      {
        name: "Phone Number",
        selector: (row) => row.ph_country_code + row.phone_number,
      },
      {
        name: "Downloading Time",
        selector: (row) => row.created_at,
      },
    ],
    []
  );

  const TimeLogClm = useMemo(
    () => [
      // {
      //   name: "Session Id",
      //   selector: (row) => row.id,
      // },
      {
        name: "Login",
        selector: (row) => row.login_date,
      },
      {
        name: "Logout",
        selector: (row) => row.logout_date,
      },
      {
        name: "Duration",
        selector: (row) => row.duration,
      },
      {
         name: "Duration seconds",
         selector: (row) => row.duration_seconds,
       },
    ],
    []
  );

  const OfferHistoryClm = useMemo(
    () => [
      {
        name: "ID",
        selector: (row) => row.id,
      },
      {
        name: "Candidate Name",
        selector: (row) => row.legal_name,
      },
      {
        name: "Candidate Email",
        selector: (row) => row.email,
      },
      {
        name: "Phone Number",
        selector: (row) => row.ph_country_code + row.phone_number,
      },
      {
        name: "Offer Time",
        selector: (row) => "",
      },
    ],
    []
  );

  const ViewProfileClm = useMemo(
    () => [
      {
        name: "ID",
        selector: (row) => row.candidate_id,
      },
      {
         name: "Candidate Name",
         selector: (row) => row.legal_name,
       },
       {
         name: "Candidate Email",
         selector: (row) => row.email,
       },
       {
         name: "Phone Number",
         selector: (row) => row.ph_country_code + row.phone_number,
       },
      {
        name: "Viewing Time",
        selector: (row) => row.updated_at,
      },
    ],
    []
  );

  const fetchUnlock = useCallback(() => {
    getUnlock({ candidateID: Number(id) })
      .then((res) => {
        setUnlocked(res.data?.data);
      })
      .catch((err) => console.log(err.message));
  }, [id]);

  const fetchDownloaded = useCallback(() => {
    getDownlodedResume({ id })
      .then((res) => {
        setDownloaded(res.data?.data);
      })
      .catch((err) => console.log(err.message));
  }, [id]);

  const fetchTimelog = useCallback(() => {
    getTimeLog({ subRecruiterId: id })
      .then((res) => {
        setTimelog(res.data?.data);
      })
      .catch((err) => console.log(err.message));
  }, [id]);

  const fetchViewed = useCallback(() => {
    getProfileView({ subRecruiter_id: id })
      .then((res) => {
        setViewed(res.data?.data);
      })
      .catch((err) => console.log(err.message));
  }, [id]);

  const fetchOffers = useCallback(() => {}, []);

  useEffect(() => {
    fetchUnlock();
  }, [fetchUnlock]);

  const handleTabSelect = useCallback(
    (key) => {
      if (key === "unlock") {
        fetchUnlock();
      } else if (key === "downloaded") {
        fetchDownloaded();
      } else if (key === "timelog") {
        fetchTimelog();
      } else if (key === "viewed") {
        fetchViewed();
      } else if (key === "offers") {
        fetchOffers();
      }
    },
    [fetchUnlock, fetchDownloaded, fetchTimelog, fetchViewed, fetchOffers]
  );

  return (
    <div className="card">
      <div className="card-body">
        <Tab.Container
          defaultActiveKey="unlock"
          onSelect={(e) => handleTabSelect(e)}
        >
          <Nav
            as="ul"
            variant="pills"
            className="nav flex-row justify-content-around mt-3"
            role="tablist"
          >
            <Nav.Item as="li" className="fs-5">
              <Nav.Link as="a" eventKey="unlock" to="#unlock">
                Unlocked Profiles
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" className="fs-5">
              <Nav.Link as="a" eventKey="downloaded" to="#downloaded">
                Downloaded Profiles
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" className="fs-5">
              <Nav.Link as="a" eventKey="timelog" to="#timelog">
                Time Logs History
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" className="fs-5">
              <Nav.Link as="a" to="#viewed" eventKey="viewed">
                Viewed Profiles
              </Nav.Link>
            </Nav.Item>
            {/*<Nav.Item as="li" className="fs-5">
              <Nav.Link as="a" to="#offers" eventKey="offers">
                Offers History
              </Nav.Link>
            </Nav.Item>*/}
          </Nav>
          <hr className="my-5" />
          <Tab.Content>
            <Tab.Pane eventKey="unlock">
              <DataTable
                //title="Unlocked Profiles"
                columns={unlockClm}
                data={unloked}
                //progressPending={loading}
                //pagination
                //paginationServer
                //paginationTotalRows={totalRows}
                //paginationDefaultPage={currentPage}
                //onChangeRowsPerPage={handlePerRowsChange}
                //onChangePage={handlePageChange}
                persistTableHead
              />
            </Tab.Pane>
            <Tab.Pane eventKey="downloaded">
              <DataTable
                //title="Downloaded Profiles"
                columns={downloadClm}
                data={downloaded}
                //progressPending={loading}
                //pagination
                //paginationServer
                //paginationTotalRows={totalRows}
                //paginationDefaultPage={currentPage}
                //onChangeRowsPerPage={handlePerRowsChange}
                //onChangePage={handlePageChange}
                persistTableHead
              />
            </Tab.Pane>
            <Tab.Pane eventKey="timelog">
              <DataTable
                //title="Time Logs History"
                columns={TimeLogClm}
                data={timelog}
                //progressPending={loading}
                //pagination
                //paginationServer
                //paginationTotalRows={totalRows}
                //paginationDefaultPage={currentPage}
                //onChangeRowsPerPage={handlePerRowsChange}
                //onChangePage={handlePageChange}
                persistTableHead
              />
            </Tab.Pane>
            <Tab.Pane eventKey="viewed">
              <DataTable
                //title="Viewed Profiles"
                columns={ViewProfileClm}
                data={viewed}
                //progressPending={loading}
                //pagination
                //paginationServer
                //paginationTotalRows={totalRows}
                //paginationDefaultPage={currentPage}
                //onChangeRowsPerPage={handlePerRowsChange}
                //onChangePage={handlePageChange}
                persistTableHead
              />
            </Tab.Pane>
            <Tab.Pane eventKey="offers">
              <DataTable
                //title="Offers History"
                columns={OfferHistoryClm}
                //data={data}
                //progressPending={loading}
                //pagination
                //paginationServer
                //paginationTotalRows={totalRows}
                //paginationDefaultPage={currentPage}
                //onChangeRowsPerPage={handlePerRowsChange}
                //onChangePage={handlePageChange}
                persistTableHead
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>

        {/* <div className="d-flex flex-row justify-content-between mb-4 flex-wrap">
          <a
            to={"#"}
            //onClick={() => setAddModal(true)}
            className="btn btn-info me-3 btn-sm"
          >
            <i className="fas fa-plus-circle me-1"> </i>New Recruiter
          </a>
          <button
            className="btn btn-warning me-3 btn-sm"
            //onClick={()=>swal("", "Work in progress", "info")}
          >
            Generate Usage Report
          </button>
          <button
            //onClick={handleDeleteRecruiter}
            className="btn btn-danger btn-sm"
          >
            <i className="fas fa-trash me-1"></i>Delete Selected
          </button>
        </div> */}
        <div className="row">
          <div className="col-xl-12">
            <div className="table-responsive"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
