import { NOTIFICATION_COUNT } from "../reducers/NotificationReducer";

export function callSetCount(count) {
  return (dispatch) => {
    return dispatch({
      type: NOTIFICATION_COUNT,
      payload: count,
    });
  };
}
