import axiosInstance from "./AxiosInstance";

export function addSubRecruiter(data) {
  return axiosInstance.post("sub-recruiter/add", data);
}

export function updateRecruiter(id, data) {
  return axiosInstance.put("sub-recruiter/update/" + id, data);
}

export function updateRecruiterStatus(data, status) {
  return axiosInstance.post("sub-recruiter/update-status", {
    ids: data,
    published: String(status),
  });
}

export function getUnlock(data) {
  return axiosInstance.post(
    "sub-recruiter/report/unlock-candidate-lists",
    data
  );
}

export function getDownlodedResume(data) {
  return axiosInstance.post("sub-recruiter/downloaded-resume", data);
}

export function getTimeLog(data) {
  return axiosInstance.post("sub-recruiter/login-logout-duration", data);
}

export function getProfileView(data) {
  return axiosInstance.post("sub-recruiter/checkout-candidates", data);
}
