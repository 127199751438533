export const GET_DASHBAORD_COUNT = "Get dashboard count";

const initialState = {
  total_favourites: 0,
  total_unlock_quota: 0,
  total_unlocked_remaining: 0,
  general_unlock_quota: 0,
  general_unlocked_remaining: 0,
  applicants_unlock_quota: 0,
  applicants_unlocked_remaining: 0,
  expire_date: "NA",
  expire_date_days: "NA",
  start_subscription: "NA",
  sub_recruiter_quota: 0,
  subrecruiter_remaining_quota: 0,
  total_jobs: 0,
  live_on_site: 0,
  remaining_jobs: 0,
  hot_jobs: 0,
  draft_jobs: 0,
  applicants: 0,
};

export default function DashboardReducer(state = initialState, actions) {
  switch (actions.type) {
    case GET_DASHBAORD_COUNT:
      return { ...state, ...actions.payload };
  }
  return state;
}
