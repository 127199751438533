const userInfo=async()=>{
    try {
        let data = localStorage.getItem("userDetails");
        return data;
      } catch (error) {
        console.error("Error fetching menu items:", error);
        return "";
      }
}

export const fetchUserData=async()=>{
  let userinfo = JSON.parse(await userInfo());
  var  config = {
      headers: {
        'Authorization': 'Bearer ' + userinfo.idToken,
        'Content-Type': 'application/json'

      }
    }
    return config;
}

export const fetchUserDataWithMutipart=async()=>{
  let userinfo = JSON.parse(await userInfo());
  var  config = {
      headers: {
        'Authorization': 'Bearer ' + userinfo.idToken,
        'Content-Type': 'multipart/form-data'

      }
    }
    return config;
}

