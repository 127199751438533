import React, { memo, useRef } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { uploadEditorFile, uploadFile } from "../../../../services/CommonService";

function SuneditorComponent(props) {
  const { ipValue = "", fieldName, setEditorValue } = props;
  const editor = useRef();
  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };

  const handleImageUploadBefore = (files, info, uploadHandler) => {
    // uploadHandler is a function
    uploadEditorFile(files[0])
      .then((res) => {
        if (res.data?.fullPath) {
          uploadHandler({
            result: [
              {
                url: res.data?.fullPath,
                name: files[0].name,
                size: files[0].size,
              },
            ],
          });
        } else {
          console.log(res.data?.error);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <SunEditor
      getSunEditorInstance={getSunEditorInstance}
      setOptions={{
        height: 450,
        buttonList: [
          ["undo", "redo"],
          ["bold", "italic", "underline"],
          ["formatBlock"],
          ["paragraphStyle"],
          ["removeFormat"],
          ["align", "horizontalRule", "list", "lineHeight"],
          ["link", "image", "video"],
          ["fullScreen", "showBlocks", "codeView"],
        ],
      }}
      onChange={(content) => {
        setEditorValue(fieldName, content);
      }}
      onImageUploadBefore={handleImageUploadBefore}
      defaultValue={ipValue}
      setContents={ipValue}
    />
  );
}

export default memo(SuneditorComponent);
