import axiosInstance from "./AxiosInstance";

export function setSelectColumn(column) {
  let putData = { fields: column };
  return axiosInstance.put("recruiter/user/update/searchkeys", putData);
}

export function getSelectedColumn(column) {
   return axiosInstance.get("recruiter/user/fetch/searchkeys");
 }
